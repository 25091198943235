@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #fff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
  --avatar-color:#C655FA;
  --animation-delay: 0s;
  --avatar-loudness: 1rem;
  --border-width: 0.4rem;
  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;
}