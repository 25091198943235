.services_area {
    display: block;
    overflow: hidden;
    padding: 190px 0 150px;
    position: relative;
}


.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.tittle {
    display: block;
    overflow: hidden;
    text-align: center;
}
.tittle h4 {
    font: 700 22px/1 "Overpass", sans-serif;
    color: #d953f9;
}
.tittle h2 {
    font: 700 45px/55px "Overpass", sans-serif;
    color: #282828;
    max-width: 700px;
    margin: 0 auto;
    padding-top: 20px;
}
.row {
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-wrap: wrap; */
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-lg-4{
    flex: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 25px;
    
}
 .services_items {
    display: block;
    overflow: hidden;
    background: #fff;
    border-radius: 10px;
    padding: 50px 40px 60px;
    max-width: 370px;
    margin: 0 auto 30px;
    transition: all 300ms linear 0s;
}
.services_items .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;
   
}
.services_items .icons {
    width: 130px;
    height: 115px;
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    padding-top: 15px;
    padding-left: 20px;
}
.services_items .icons img {
   align-self: center;
    max-width: 100%;
    width: auto;
    height: 5rem;
    vertical-align: middle;
    border-style: none;
  
}
.services_items .icons span {
    position: absolute;
    right: 4px;
    top: 25px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #d953f9;
    animation: heartbeat 0.5s cubic-bezier(0.245, 0.325, 0.51, 1.305) infinite alternate;
}
 .services_items a {
    display: block;
    font: 600 24px/1 "Overpass", sans-serif;
    color: #282828;
    padding: 42px 0 28px;
}

.tittle {
    padding-bottom: 100px;
    display: block;
    overflow: hidden;
    text-align: center;
}
 .theme_btn {
    color: #fff;
    text-align: center;
    font: 400 18px/57px "Overpass", sans-serif;
    display: inline-block;
    padding: 0 20px;
    background-image: linear-gradient(to right, #a227f9 0%, #dd56f9 51%, #a227f9 100%);
    background-size: 200% auto;
    z-index: 1;
    border-radius: 30px;
    padding-top: 3px;
    /* background-image: linear-gradient(to right, #9b79fb 0%, #5468ef 51%, #9b79fb 100%); */
}
