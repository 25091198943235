.zima__hero{
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 1.5rem; */
    padding: 0.5em 0 4rem;
    text-align: center;
    background: #000;
    height: 480px;
    
}

.zima__hero-interaction{
   
    top:  1vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0.5em 0;
    text-align: center;
    font-family: var(--font-family);
    font-size: 65px;
    font-weight: bolder;
}

.zima__hero-headline{
    z-index: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    font-size: 1em;
    color: #fff;
}

.zima__hero-headline_line{
    position: relative;
    font-family: var(--font-family);
    display: inline-block;
    margin: 0.01rem auto;
    padding: 0 0.1em 0.15em;
    font-weight:bolder;
    
}
.red_color{
   color:  #da0669; 
}

.zima__hero-subtext{
    position: absolute;
    top: 280px;
    align-self: center;
    max-width: 120ch;
    padding: 0 1ch;
    color: #f3f3f3;
    font-family: var(--font-family);
    font-size: 9px;
    line-height: 1.6;
    font-weight: 100;
    
}

.live-cursor{
    --avatar-loudness: 0.8rem;
    --border-width: 0.3rem;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.3rem 1.6rem;
    transition: top 200ms linear, left 200ms linear, opacity 125ms linear;
    white-space: nowrap;
    pointer-events: auto;
    border-radius: 2em;
    background-color: var(--avatar-color);
    box-shadow: 0 0.1rem 0.3rem rgb(25 25 25 / 18%);
    will-change: top, left;
    
}
.live-cursor.speaking::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: calc(var(--border-width) * -1);
    content: "";
    animation: 3s noise var(--animation-delay) ease-in-out infinite alternate;
    opacity: 0.5;
    border-radius: 2em;
    background: var(--avatar-color);
}
.live-cursor.video {
    padding: 0;
    border: var(--border-width) solid var(--avatar-color);
    border-radius: 50%;
}

.live-cursor.home-hero__1 {
    top: 2.3em;
    left: 4em;
    animation: fadein 0.3s 0.3s ease-out backwards;
}



.live-cursor.home-hero__2 {
    top: 3.1em;
    right: 4.3em;
    animation: fadein 0.3s 0.4s ease-out backwards;
}

.home-hero__2 {
    --avatar-color: #da0669;
    --animation-delay: 1s;
}
.live-cursor__video-wrapper{
    position: relative;
    overflow: hidden;
    width: 6rem;
    height: 6rem; 
  
    border-radius: 50%;
}

.live-cursor__video-wrapper img{
    width: 6rem;
    height: 6rem;
    
}

.sandwich-video-cta {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font-family);
    color: currentColor;
    border: none;
    background: none;
    appearance: none;
    top: 100px;
    justify-content: space-between;
    align-items: center;
}

 .sandwich-video-cta__wrapper {
    transition: background-position 0.5s ease-in, color 0.5s ease-in;
    color: #9758fd;
    background-color: #9758fd;
    background-image: linear-gradient(90deg, #9758fd, #4c66f8, #9758fd);
    background-position: 100% 0%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    background-size: 200% 100%;
    font-size: 1rem;
    font-weight: bold;
    /* line-height: 1em; */
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.sandwich-video-cta__icon {
    position: relative;
    display: inline-block;
    width: 2.6rem;
    height: 2.6rem;
    margin: -0.3rem 1rem 0 0;
    content: '';
    vertical-align: middle;
    

}

.sandwich-video-cta__icon:hover{
    color:var(--avatar-color);
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
}