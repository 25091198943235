.i-second {
    font-family: var(--font-family);
    margin-top: 0%;
    padding-top: 7%;
    padding-bottom: 3%;
    /* background: url(./../images/Trusted_all_ove_the_world.svg); */
    background-position-y: 0%;
    background-size: auto;
    background-position-y: center;
    background-size: cover;
    color: #000;
}
._container {
    padding: 0 10%;
    display: flex;
}
 .a {
    width: 50%;
    flex-basis: 50%;
    padding-top: 5rem;
}
 .a h1 {
    margin-bottom: 1%;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2rem;
}
.a h1 span {
    font-weight: bolder;
    color: #9758fd;
}
.i-second ._container .a h1 span span {
    color:#9758fd;
    margin-left: -0.2em;
}

 .a p {
    padding-top: 0;
    color: #000;
    line-height: 1.6em;
    font-size: 1.1rem;
}

 .a  a {
    text-decoration: none;
   
    line-height: 1.9em;
    font-weight: 300;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.904);
    font-weight: 400;
}

.a  a:hover{
    color:#9758fd;
}

.b {
    width: 50%;
    flex-basis: 50%;
    display: flex;
    margin-left: 2rem;
    justify-content: flex-end;
    /* padding-left: 2rem; */
    /* border-radius: 50%; */
    /* background: linear-gradient(90deg, #9758fd, #4c66f8, #9758fd); */
    /* box-shadow: 0 0.8rem 0.8rem rgb(25 25 25 / 28%) */
    
}
img{
    max-width: 100%;
    width:auto;
    height: 26rem;
    /* border-radius: 50%; */
}