.zima__navbar{
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    background: #000;
    margin-bottom:0 ;
}

.zima__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.hover-board{
    background-color: #fff;
}

.zima__navbar-links_logo {
    margin-right: 4rem;
}

.zima__navbar-links_logo img {
    width: 100px;
    height: 20.02px;
}

.zima__navbar-links_container{
    display: flex;
    flex-direction: row;
    
   
}


.zima__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 30%;
}
.zima__navbar-sign button{
    margin-left: 30%;
}
.zima__navbar-menu{
    display: none;
}
.zima__navbar-links_container p, 
.zima__navbar-sign p,
.zima__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    font-style: normal;
    
    margin: 0 1rem;
    cursor: pointer;
}

.zima__navbar-links_container p:hover{
    color:#C655FA;
}

.zima__navbar-sign button, 
.zima__navbar-menu_container button{
   padding: 0.8rem 1.4rem;
    left: 1153px;
    top: 55px;
    border: none;
    border-radius: 5px;
    background: #C655FA;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 2rem;

}

