.darkBG {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: auto;
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    overflow: hidden;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal {
    width: 700px;
    height: auto;
    background: white;
    color: white;
    z-index: 100;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  

  

  
  .modalContent {
    padding: 10px;
    
  }
  
 
  
  
  
  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
  }
  
  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }
  
  