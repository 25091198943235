.information{
    font-family: var(--font-family);
    padding: 5rem 0;
    margin: 0;
    box-sizing: border-box;
}
.columns__wrapper{
    width: 85%;
    margin: 0 auto;
}
.screen__container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 1440px;
    padding-right: 20px;
    padding-left: 20px;
    width: 85%;
}
.column__left{
    flex: 0 0 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.column__header{
    margin-bottom: 2.5rem;
    text-align: left;
    width: 100%;
}
.column__header h3{
    font-family: var(--font-family);
    font-size: 40px;
    line-height: 60px;
}
.column__header p{
    width: 60%;
    font-family: "Jost",sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
}
.card{
    font-family: var(--font-family);
    position: relative;
    width: auto;
    max-width: 540px;
    height: auto;
    border-radius: 24px;
    padding: 40px 40px 0;
    background-color: rgb(219, 213, 229);
}
.card__header{
    font-family: var(--font-family);
    position: relative;
    width: auto;
    max-width: 540px;
    height: auto;
    border-radius: 24px;
    padding: 40px 40px 0;
}
.card__header .subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #492b7c;
}


.card__header .title {
    font-style: normal;
    font-size: 32px!important;
    line-height: 50px;
    font-weight: 700;
    margin: 1rem 0;
    color: #000;
    font-family: "Jost",sans-serif!important;
}

.btn {
    max-width: 320px;
    padding: 16px 32px;
    height: 56px;
    width: auto;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: box-shadow .4s ease;
    text-align: center;
    position: relative;
    background: #000;
    color: #fff;
    background-position: 50%;
    transition: background .8s;
}
.card-image {
    margin-bottom: 16px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.card-image img {
    height: auto;
    width: 300px;
    max-width: 100%;
}
.column__right{
    flex: 0 0 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.card1{
    font-family: var(--font-family);
    position: relative;
    width: auto;
    max-width: 540px;
    height: auto;
    border-radius: 24px;
    padding: 40px 40px 0;
    background-color: rgb(220, 237, 232);
}
.card-image1 {
    margin-bottom: 16px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.card-image1 img {
    height: auto;
    width: 300px;
    max-width: 100%;
}