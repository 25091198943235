.blog__container{
    padding: 0 1.5rem;
}

.inner__container{
    max-width: 138rem;
    margin: 0 auto;
}

.spacing__bottom{
    width: 100%;
    padding-bottom: 3rem;
}
.blog__slide{
    --transition-duration: 0ms;
    --half-transition-duration: 0ms;
}
.blog__content{
    overflow: hidden;
}
.custom__label{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.8rem;
    padding: 0 1.6rem;
    padding-bottom: 0.25em;
    white-space: nowrap;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0.6rem;
    background: linear-gradient(90deg, #4c66f8, #9758fd);
    font-family: var(--font-family);
    font-size: 1.3rem;
    font-weight: 700;
}
.blog__posts{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1.1rem;
}
.blog__post{
    width: 100%;
}
.blog__post h3{
    letter-spacing: -0.02em;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
}
.blog__post p {
    word-break: break-word;
}
.read__more{
    transition-duration: 0ms;
    --half-transition-duration: 0ms;
}
.read-more-link{
    display: inline-block;
    margin-top: 2rem;
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: bold;
    color: currentColor;
    border: none;
    background: none;
    appearance: none;
}
.read_more_content{
    transition: background-position 0.5s ease-out, color 0.5s ease-out;
    color: #9758fd;
    border-radius: 0.1rem;
    background-color: #9758fd;
    background-image: linear-gradient(90deg, #9758fd, #4c66f8, #9758fd);
    background-position: 100% 0%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    background-size: 200% 100%;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-size: 1.8rem;
    font-weight: bold;
    cursor: pointer;
}