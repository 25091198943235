.page-home__teams {
    text-align: center;
    background: linear-gradient(to right, #a227f9 0%, #dd56f9 51%, #a227f9 100%);
    
}

.container-inner {
    max-width: 128rem;
    margin: 0 auto;
}
.spacing {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}
.team-logos {
    display: flex;
    opacity: 0.75;
    justify-content: space-evenly;
    width: 30%;
    margin: 0 auto;
    list-style: none;
   
}

.team-logos__logo {
    flex: 0 0 auto;
    width: 40%;
    padding: 2rem 0;
}

.team-logos__logo span {
    position: relative;
    display: block;
    padding-bottom: 20%;
    content: '';
}

.team-logos__logo img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: auto;
    object-fit: contain;
}